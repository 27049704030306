import * as TYPES from "./types";
import { getHubItems, getHubMeta } from "@/api/hub";

const state = () => ({
  items: [],
  meta: {},
});

const actions = {
  async [TYPES.GET_HUB_ITEMS]({ commit }) {
    const data = await getHubItems();
    commit(TYPES.PUT_HUB_ITEMS, data);
  },
  async [TYPES.GET_HUB_META]({ commit }) {
    const data = await getHubMeta();
    commit(TYPES.PUT_HUB_META, data);
  },
};

const mutations = {
  [TYPES.PUT_HUB_ITEMS](state, payload) {
    state.items = payload;
  },
  [TYPES.PUT_HUB_META](state, payload) {
    state.meta = payload;
  },
};

const getters = {
  hubItems: (state) => state.items.sort((a, b) => a.order - b.order),
  hubMeta: (state) => state.meta,
};

export default {
  state,
  mutations,
  getters,
  actions,
};
