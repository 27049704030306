import * as TYPES from "./types";
import { getMuseums, getMuseum } from "@/api/museums";

const state = () => ({
  museums: [],
  museum: {},
  recommendations: [],
});

const actions = {
  async [TYPES.GET_MUSEUMS]({ commit }, payload) {
    await getMuseums(payload)
      .then((res) => {
        commit(TYPES.SET_DATA, res);
      })
      .catch((err) => console.log(err));
  },
  async [TYPES.GET_MUSEUM]({ commit }, payload) {
    await getMuseum(payload)
      .then((res) => {
        commit(TYPES.SET_MUSEUM, res);
      })
      .catch((err) => console.log(err));
  },
  async [TYPES.GET_RECOMMENDATION]({ commit }, payload) {
    let recommendations = [];
    for (let i = 0; i < payload.length; i++) {
      await getMuseum(payload[i])
        .then((res) => (recommendations = [...recommendations, res]))
        .catch((error) => console.log(error));
    }
    commit(TYPES.SET_RECOMMENDATION, recommendations);
  },
};

const mutations = {
  [TYPES.SET_DATA](state, payload) {
    state.museums = payload;
  },
  [TYPES.SET_MUSEUM](state, payload) {
    state.museum = payload;
  },
  [TYPES.SET_RECOMMENDATION](state, payload) {
    state.recommendations = payload;
  },
};

const getters = {
  museums: (state) => state.museums,
  museum: (state) => state.museum,
  recommendations: (state) => state.recommendations,
};

export default {
  state,
  mutations,
  getters,
  actions,
};
