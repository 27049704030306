import * as TYPES from "./types";

const state = () => ({
  isOpenModal: false,
});

const mutations = {
  [TYPES.CHANGE_STATE](state, payload) {
    state.isOpenModal = payload;
  },
};

const getters = {
  stateModal: (state) => state.isOpenModal,
};

export default {
  state,
  mutations,
  getters,
};
