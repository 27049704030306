import * as TYPES from "./types";

const state = () => ({
  where: null,
});

const mutations = {
  [TYPES.GET_WHERE](state, payload) {
    const where = require(`@/../data/${payload}/where.json`);
    state.where = where;
  },
};

const getters = {
  museumWhere: (state) => state.where,
};

export default {
  state,
  mutations,
  getters,
};
