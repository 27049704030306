<template>
  <div class="page">
    <router-view />
  </div>
  <footer>
    <Rules />
    <Join project-name="museums" />
  </footer>
  <!-- <Footer /> -->
</template>

<script>
import Join from "@/components/Join.vue";
// import Footer from "@/components/Footer";
import Rules from "@/components/Rules.vue";

export default {
  components: {
    Join,
    // Footer,
    Rules,
  },
};
</script>

<style lang="scss" src="@/styles/index.scss"></style>
