import * as TYPES from "./types";
import { getCities } from "@/api/cities";

const state = () => ({
  city: null,
  cities: [],
  cityModal: false,
});

const actions = {
  async [TYPES.GET_CITIES]({ commit }) {
    const data = await getCities();
    commit(TYPES.PUT_CITIES, data);
  },
};

const mutations = {
  [TYPES.PUT_CITY](state, payload) {
    localStorage.setItem("userCity", payload);
    state.city = payload;
  },
  [TYPES.PUT_CITIES](state, payload) {
    state.cities = payload;
  },
  [TYPES.OPEN_MODAL](state) {
    state.cityModal = true;
  },
  [TYPES.CLOSE_MODAL](state) {
    state.cityModal = false;
  },
};

const getters = {
  userCity: (state) => state.city,
  cities: (state) => state.cities,
  cityModal: (state) => state.cityModal,
};

export default {
  state,
  mutations,
  getters,
  actions,
};
