import request from "@/utils/request";

export function getHubItems() {
  return request({
    url: `/api/Hub/GetHubElements`,
    method: `get`,
  });
}

export function getHubMeta() {
  return request({
    url: `/api/Hub/GetHub`,
    method: `get`,
  });
}
