import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixin from "./mixins/device";
import VueScrollTo from "vue-scrollto";

const app = createApp(App);

app.use(store).use(router).use(VueScrollTo).mixin(mixin).mount("#app");
