import * as TYPES from "./types";
import { getMuseumVideos } from "@/api/museums";

const state = () => ({
  // from data
  video: null,
  // from back
  videos: {
    online_ex: [],
    lectures: [],
  },
});

const actions = {
  async [TYPES.FETCH_VIDEOS]({ commit }, { id, type }) {
    await getMuseumVideos(id, type)
      .then((resp) =>
        commit(type ? TYPES.SET_VIDEOS_L : TYPES.SET_VIDEOS_O, resp)
      )
      .catch((err) => console.log(err));
  },
};

const mutations = {
  [TYPES.GET_VIDEO](state, payload) {
    const video = require(`@/../data/${payload}/video.json`);
    state.video = video;
  },
  [TYPES.SET_VIDEOS_L](state, payload) {
    state.videos.lectures = payload;
  },
  [TYPES.SET_VIDEOS_O](state, payload) {
    state.videos.online_ex = payload;
  },
};

const getters = {
  museumVideo: (state) => state.video,
  videos: (state) => state.videos,
};

export default {
  actions,
  state,
  mutations,
  getters,
};
