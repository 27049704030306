import { createRouter, createWebHistory } from "vue-router";
// import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    // redirect: "/hub",
    redirect: "/museum/13", // временный редирект
  },
  {
    path: "/hermitage",
    name: "Hermitage",
    // component: () =>
    //   import(/* webpackChunkName: "hermitage" */ "../views/Hermitage.vue"),
    redirect: "/museum/14", // старый адрес музея /hermitage перенаправляем на новый
  },
  {
    path: "/naiv",
    name: "Naiv",
    // component: () => import(/* webpackChunkName: "naiv" */ "../views/Naiv.vue"),
    redirect: "/museum/15", // старый адрес музея /naiv перенаправляем на новый
  },
  // {
  //   path: "/hub",
  //   name: "Hub",
  //   component: () => import(/* webpackChunkName: "naiv" */ "../views/Hub.vue"),
  // },
  {
    path: "/museum/:id",
    name: "Museum",
    component: () =>
      import(/* webpackChunkName: "naiv" */ "../views/MuseumPage.vue"),
  },
  {
    path: "/izo",
    name: "Home",
    redirect: "/museum/13", // старый адрес музея /naiv перенаправляем на новый
    // component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return {
        left: 0,
        top: 0,
      };
    }
  },
});

export default router;
