import * as TYPES from "./types";
import { getMuseumHalls } from "@/api/museums";

const state = () => ({
  // from data
  hall: null,
  // from back
  halls: null,
});

const actions = {
  async [TYPES.FETCH_HALLS]({ commit }, payload) {
    await getMuseumHalls(payload)
      .then((resp) => commit(TYPES.SET_HALLS, resp))
      .catch((err) => console.log(err));
  },
};

const mutations = {
  [TYPES.GET_HALLS](state, payload) {
    const { halls } = require(`@/../data/${payload}/audio-guide.json`);
    state.hall = halls;
  },
  [TYPES.SET_HALLS](state, payload) {
    state.halls = payload;
  },
};

const getters = {
  museumHalls: (state) => state.hall,
  fetchHalls: (state) => state.halls.sort((a, b) => a.order - b.order),
};

export default {
  actions,
  state,
  mutations,
  getters,
};
