import { createStore } from "vuex";
import modal from "./modal";
import info from "./info";
import events from "./events";
import video from "./video";
import activation from "./activation";
import where from "./where";
import address from "./address";
import header from "./header";
import audio from "./audio";
import city from "./city";
import museums from "./museums";
import hub from "./hub";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    modal,
    info,
    events,
    video,
    activation,
    where,
    address,
    header,
    audio,
    city,
    museums,
    hub,
  },
});
