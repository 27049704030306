import * as TYPES from "./types";

const state = () => ({
  address: null,
});

const mutations = {
  [TYPES.GET_ADDRESS](state, payload) {
    const address = require(`@/../data/${payload}/address.json`);
    state.address = address;
  },
};

const getters = {
  museumAddress: (state) => state.address,
};

export default {
  state,
  mutations,
  getters,
};
