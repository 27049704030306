import axios from "axios";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
    ? process.env.VUE_APP_BASE_API
    : process.env.VUE_APP_PUBLIC_PATH,
});

service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default service;
