import * as TYPES from "./types";

const state = () => ({
  header: null,
});

const mutations = {
  [TYPES.GET_HEADER](state, payload) {
    const header = require(`@/../data/${payload}/header-menu.json`);
    state.header = header;
  },
};

const getters = {
  museumHeader: (state) => state.header,
};

export default {
  state,
  mutations,
  getters,
};
