import * as TYPES from "./types";

const state = () => ({
  info: null,
});

const mutations = {
  [TYPES.GET_INFO](state, payload) {
    const about = require(`@/../data/${payload}/about.json`);
    state.info = about;
  },
};

const getters = {
  museumInfo: (state) => state.info,
};

export default {
  state,
  mutations,
  getters,
};
