import request from "@/utils/request";

export function getMuseums(id) {
  const params = id ? { cityId: id } : null;
  return request({
    url: `/api/Museums/GetMuseums${id ? "/" + id : ""}`,
    method: `get`,
    params,
  });
}

export function getMuseum(id) {
  const params = { id: id };
  return request({
    url: `/api/Museums/GetMuseum/${id}`,
    method: `get`,
    params,
  });
}

export function getMuseumActivations(id) {
  const params = { museumId: id };
  return request({
    url: `/api/MuseumActivations/MuseumActivations/${id}`,
    method: `get`,
    params,
  });
}

// TODO: доделать type = 0
export function getMuseumVideos(id, type = 1) {
  const params = { museumId: id, videoType: type };
  return request({
    url: `/api/Videos/GetMuseumVideos/${id}`,
    method: `get`,
    params,
  });
}

export function getMuseumHalls(id) {
  const params = { museumId: id };
  return request({
    url: `/api/ExcursionHalls/GetHalls/${id}`,
    method: `get`,
    params,
  });
}

export function getMuseumEvents(id) {
  const params = { museumId: id };
  return request({
    url: `/api/Shows/GetShows/${id}`,
    method: `get`,
    params,
  });
}
