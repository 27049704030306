import * as TYPES from "./types";
import { getMuseumActivations } from "@/api/museums";

const state = () => ({
  // из data
  activation: null,
  // с бека
  activations: null,
});

const actions = {
  async [TYPES.FETCH_ACTIVATIONS]({ commit }, payload) {
    await getMuseumActivations(payload)
      .then((resp) => commit(TYPES.SET_ACTIVATIONS, resp))
      .catch((err) => console.log(err));
  },
};

const mutations = {
  [TYPES.GET_ACTIVATION](state, payload) {
    const activation = require(`@/../data/${payload}/activation-items.json`);
    state.activation = activation;
  },
  [TYPES.SET_ACTIVATIONS](state, payload) {
    state.activations = payload;
  },
};

const getters = {
  museumActivation: (state) => state.activation,
  museumActivations: (state) => state.activations,
};

export default {
  actions,
  state,
  mutations,
  getters,
};
